.hover:hover
{
    cursor: pointer;
}

.content-bg {
    --background: #eaeaea;
}

.card-bg {
    --background: #fff;
}

.ion-card-title-css {
    margin-top: 2px;
    font-size: 22px;
}

.marginBottom {
    margin-bottom: 25px;
}

ion-card-header:before {
    background: transparent !important;
}
.ml-0 {
    margin-left: 0px !important;
}
