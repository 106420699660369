.event-content {
    
    /*--offset-top:100px;*/
    /*--padding-top:50px;*/
    /*--margin-top: 300px;*/
}

.no-padding
{
    /*There was a black border showing behind it on white mode in browser, and a white one on black mode on phone. Hackage until I get better at styling.*/
    padding-inline: unset;
    padding:unset;
}

