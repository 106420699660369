Ion-Card.checked-in
{
    border-left: 10px solid green;
}

Ion-Card.not-checked-in
{
    border-left: 10px solid red;
    
}

Ion-Icon.checked-in
{
    color: green;
}

Ion-Icon.not-checked-in
{
    color: red;

}